<template>
  <div>
    <Dropdown
      ref="dropdown"
      class="shape-custom-select"
      :options="rows"
      label="name"
      :required="required && rows.length > 0"
      @input="onInput"
      @search="onSearch"
      :value="rows.find(r => r.id == (currentEditedEntry.refProduct || currentEntry.refProduct))"
    />

    <router-link
      v-if="currentEditedEntry.product_id || currentEntry.product_id"
      :to="{
        name: 'inputStock.info',
        params: { product_id: currentEditedEntry.product_id || currentEntry.product_id },
      }"
      class="mt-2 btn bg-text-color text-infos config-density-semence"
    >
      {{ $t("inputStock.details.see_product_infos") }}
    </router-link>
  </div>
</template>

<script>
import { mapGetters } from "vuex";

import Dropdown from "@/components/inputstock/details/Dropdown.vue";

import DetailUpdateMixin from "@/mixins/DetailUpdateMixin";
import ProductSelectionMixin from "@/mixins/ProductSelectionMixin";

import { SET_PRODUCT_TYPE } from "@/store/modules/productType";

import { EventBus } from "@/eventBus.js";

export default {
  data() {
    return {};
  },
  mixins: [DetailUpdateMixin, ProductSelectionMixin],
  components: {
    Dropdown,
  },
  mounted() {
    EventBus.$on("resetProductSelection", () => {
      this.value = null;
      this.$store.dispatch(this.storeEditAction, {
        refProduct: null,
        product_id: null,
      });
      if (this.$refs.dropdown) this.$refs.dropdown.clear();
    });
    this.loadProductAliases({ name: "" });
    if(this.currentEntry.refProduct){
      this.$store.dispatch("inputStock/setRefProduct", this.currentEntry.refProduct);
    }
  },
  props: {
    required: {
      type: Boolean,
      default: true,
    },
  },
  computed: {
    ...mapGetters({
      rows: "researchResults",
      productFamilies: "productFamilies",
      productTypesByFamily: "productTypesByFamily",
      researchResults: "researchResults",
      currentProduct: "currentProduct",
    }),
    currentEntry() {
      return this.$store.getters[this.storeReadGetter];
    },
  },
  methods: {
    async onInput(value) {
      // On récupère le produit du catalogue associé
      if (value && value.product){
        await this.$store.dispatch("getProductInfo" , {id: value.product});
      }
      let toUpdate = {
        refProduct: (value && value.id) || null,
        name: value && value.name,
        product_id: value && value.product,
        minDoseValue: this.currentProduct?.productUsages?.length > 0 ? this.currentProduct?.productUsages[0]?.minValue : null,
        maxDoseValue: this.currentProduct?.productUsages?.length > 0 ? this.currentProduct?.productUsages[0]?.maxValue : null,
        doseUnit: this.currentProduct?.productUsages?.length > 0 ? this.currentProduct?.productUsages[0]?.doseUnit : null,
        percentMineralizableNitrogen: this.currentProduct?.percentMineralizableNitrogen
      };
      if (value && value.productType_id) toUpdate["productType"] = value.productType_id;
      // set the Ref Product in the store
      if(value && value.id) this.$store.dispatch("inputStock/setRefProduct", value.id);
      await this.$store.dispatch(this.storeEditAction, toUpdate);
    },
    onSearch(value) {
      this.loadProductAliases({ name: value });
    },
  },
  watch: {
    rows() {
      this.limit = this.initialLimit;
      this.value = this.rows.find(p => p.product == this.currentEditedEntry.product_id || this.currentEntry.product_id);
      // S'il y'a un seul produit proposé, il est directement sélectionné
      if (this.rows && this.rows.length === 1) {
        this.onInput(this.rows[0]);
      }
    },
  },
};
</script>
