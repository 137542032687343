import {
  SET_PRODUCT_TYPE,
  CLEAN_PRODUCT_TYPE,
  CLEAN_PRODUCT_TYPE_BY_FAMILY,
  CLEAN_INPUTFORM_BY_PRODUCT_TYPE,
} from "@/store/modules/productType";
import { CLEAN_RESEARCH_RESULTS } from "@/store/modules/productsAliases";
import { mapActions, mapGetters } from "vuex";

export default {
  computed: {
    ...mapGetters({
      rows: "researchResults",
      productFamilies: "productFamilies",
      productTypesByFamily: "productTypesByFamily",
      researchResults: "researchResults",
    }),
    currentProductFamily() {
      return this.productFamilies.find(
        p => p.code == (this.currentEntry.productFamily_code || this.currentEditedEntry.productFamily_code),
      );
    },
    currentProductType() {
      let ptype = this.currentEntry.productType;
      if ("productType" in this.currentEditedEntry) ptype = this.currentEditedEntry.productType;
      return this.productTypesByFamily.find(p => p.id == ptype);
    },
  },
  methods: {
    async loadProductAliases({ name }) {
      if (name.length >= 3)
        await this.$store.dispatch("researchProductsAliases", {
          name: name,
          idProductType: this.currentProductType && this.currentProductType.id,
          idProductFamily: this.currentProductFamily && this.currentProductFamily.id,
        });
      else if (this.currentProductType)
        await this.$store.dispatch("getProductsAliases", { id: this.currentProductType.id });
    },
    async loadProductTypeData({ name } = { name: "" }) {
      // this.clearProductTypeData();

      if (this.currentProductType) this.$store.commit(SET_PRODUCT_TYPE, { id: this.currentProductType.id });

      await this.loadProductAliases({ name });
      if (this.currentProductType)
        this.$store.dispatch("getinputFormByProductType", { id: this.currentProductType.id });
    },
    clearProductTypeData() {
      this.$store.commit(CLEAN_RESEARCH_RESULTS);
      this.$store.commit(CLEAN_PRODUCT_TYPE);
      this.$store.commit(CLEAN_INPUTFORM_BY_PRODUCT_TYPE);
    },
  },
};
